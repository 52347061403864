import React from 'react';
import raw from 'raw.macro';
import { Typography } from '@material-ui/core';
import Markdown from './Markdown';

const Publications = () => (
  <>
    <Typography variant="h4">Juno Publications</Typography>
    <Markdown md={raw('../content/juno-publications.md')} />
  </>
);

export default Publications;
