import React from 'react';
import Markdown from 'react-markdown';
import { Grid, Box, Typography, Link } from '@material-ui/core';

import currentTeam from '../content/team';

const renderers = {
  p: ({ children }) => (
    <Typography variant="body2">{children}</Typography>
  ),
  a: props => <Link {...props} target="_blank" rel="noopener noreferrer" />,
};

const TeamDescriptionRenderer = ({ md }) => (
  <Markdown children={md} components={renderers} />
);

const Person = ({ name, role, imageUrl, description }) => (
  <Box>
    <img
      style={{ border: '2px solid white', margin: '2px' }}
      src={imageUrl}
      alt={`${name} - ${role}`}
      width="100%"
    />
    <Typography variant="h6">{name}</Typography>
    <Typography variant="subtitle1">
      <i>{role}</i>
    </Typography>
    <TeamDescriptionRenderer md={description} />
  </Box>
);

const Team = () => (
  <>
    <Typography variant="h4">Current Members</Typography>
    <Box pt={2} pb={4}>
      <Grid container spacing={2}>
        {currentTeam.filter(person => person.current === true).map(person => (
          <Grid key={person.name} item xs={12} sm={6} md={4}>
            <Person {...person} />
          </Grid>
        ))}
      </Grid>
    </Box>
    <Typography variant="h4">Former Members</Typography>
    <Box pt={2} pb={2}>
      <ul>
        {currentTeam.filter(person => person.current === false).map(person => (
          <Typography variant="subtitle1"> <li>{person.name}</li></Typography>
        ))}
      </ul>
    </Box>
  </>

);

export default Team;
