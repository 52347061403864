import React from 'react';
import raw from 'raw.macro';
import {Box, Grid } from '@material-ui/core';

import Markdown from './Markdown';

import news from '../content/news';


const News = () => (
  <Box pt={2} pb={2}>
    <Grid container spacing={2} alignItems="center">
      {news.map(resource => {
        const md = resource.md
        return (
          <>
            <Grid key={resource.alt} item xs={4}>
              <Box>
                <img
                  style={{ border: '2px solid white', margin: '2px' }}
                  src={resource.img}
                  alt={resource.alt}
                  width="100%"
                />
              </Box>
            </Grid>
            <Grid key={resource.md} item xs display='flex' alignItems='center'>
              <Box>
                <Markdown md={raw(`../content/${md}`)}/>
              </Box>
            </Grid>
          </>
        )
      })}
    </Grid>
  </Box>
);

export default News;
